












































































































































import { Component, Vue } from 'vue-property-decorator'
import { getList, getTags, updateSynchronization } from '@/api/consoleApi/recipients'
import axios from 'axios'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { UserModule } from '@/store/modules/user'
import VsTagNameModal from '@/modules/lists/components/VsTagNameModal/Index.vue'
import { unionBy } from 'lodash'

@Component({
    name: 'VsImportSettingsModal',
    components: {
        VsTagNameModal,
    },
})
export default class extends Vue {
    private open = false

    private loading = false
    private syncId = null
    private updateIfDuplicate = false
    private subscriberStatus = 'Subscribed'
    private triggers = false
    private sendConfirmationEmail = false
    private selectedTagMulti: any[] = []
    private period = 'monthly'
    private loadingTags = false
    private tags: any[] = []
    private cachedTags: any[] = []
    private assignedTagIds: any[] = []
    private list = {
        opt_in: {
            mode: 'double',
        },
    }

    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get user () {
        return UserModule.user
    }

    get hasTag () {
        return this.user.configuration.rules.tag
    }

    get tagsOptions () {
        return this.tags.filter(el => !this.assignedTagIds.includes(el.id)).map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })
    }

    public async openModal (synchronization: any) {
        this.loading = true
        this.getList(this.listId)
        await this.getTags('')
        this.syncId = synchronization.id
        this.updateIfDuplicate = synchronization.payload.settings.update_if_duplicate
        this.subscriberStatus = synchronization.payload.settings.subscriber_status
        this.triggers = synchronization.payload.settings.triggers.automation && synchronization.payload.settings.triggers.behaviors
        this.sendConfirmationEmail = synchronization.payload.settings.triggers.send_confirmation_email
        this.selectedTagMulti = synchronization?.payload?.settings?.tags || []
        this.period = synchronization.period
        this.open = true
        this.loading = false
    }

    private async getTags (search: string) {
        this.loadingTags = true
        try {
            const resp = await getTags(
                this.listId,
                {
                    limit: 50,
                    search: `name:${search}`,
                    searchFields: 'name:like',
                })
            this.tags = resp.data.data
            this.setCachedTags(resp.data.data)
        } catch (e) {
            this.tags = []
        }
        this.loadingTags = false
    }

    private setCachedTags (tags: any[]) {
        this.cachedTags = unionBy(
            this.cachedTags,
            tags
                .map((tag: any) => {
                    return {
                        value: tag.id,
                        label: tag.name,
                    }
                }),
            'value',
        )
    }

    public closeModal () {
        this.open = false
    }

    private async submit () {
        if (this.selectedTagMulti.length > 3) return
        try {
            await updateSynchronization(this.listId, this.syncId, {
                period: this.period,
                payload: {
                    settings: {
                        update_if_duplicate: this.updateIfDuplicate,
                        subscriber_status: this.list.opt_in.mode === 'double' ? this.subscriberStatus : 'Subscribed',
                        tags: this.hasTag ? this.selectedTagMulti : undefined,
                        triggers: {
                            automation: this.triggers,
                            behaviors: this.triggers,
                            send_confirmation_email: this.sendConfirmationEmail,
                        },
                    },
                },
            })
            this.$emit('saved')
            this.$root.$vsToast({
                heading: this.$t('lists.sync.syncUpdated'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.closeModal()
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('lists.sync.syncUpdatedError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private async getList (listId: any) {
        try {
            const resp = await getList(listId)
            this.list = resp.data.data
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) this.$router.replace({ name: 'listsIndex' })
            }
            console.log(e)
        }
    }

    formatBadgeLabel (item: any) {
        return this.cachedTags.find((el: any) => el.value === item)?.label || `Etichetta con id ${item} non trovata`
    }
}
