







































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'

@Component({
    name: 'VsImportSourceDetailsModal',
    components: {
        VsContainer,
        VsFullTopBarLayout,
    },
})
export default class extends Vue {
    private open = false

    private loading = false
    private importStrategy = null
    private importStrategySettings = null

    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    public async openModal (synchronization: any) {
        this.loading = true
        this.importStrategy = synchronization.payload.import_strategy
        this.importStrategySettings = synchronization.payload.import_strategy_settings
        this.open = true
        this.loading = false
    }

    public closeModal () {
        this.open = false
    }
}
