
































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsMapField from '@/modules/lists/components/VsMapField/Index.vue'
import { CustomField } from '@/utils/customFields'
import { getList, getListCustomFields, updateSynchronization } from '@/api/consoleApi/recipients'
import axios from 'axios'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsMappedFieldsModal',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsMapField,
    },
})
export default class extends Vue {
    private open = false

    private loading = false
    private customFields: CustomField[] = []
    private preprocessData = {}
    private mappedFields: any[] = []
    private list = null
    private syncId = null

    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    public async openModal (synchronization: any) {
        this.loading = true
        this.syncId = synchronization.id
        this.mappedFields = synchronization.payload.mapped_fields
        this.preprocessData = {
            pre_processing_info: synchronization.preprocess_result,
            strategy_settings: {
                skip_first_row: false,
            },
            strategy: synchronization.payload.import_strategy,
        }
        this.open = true
        await this.getList(this.listId)
        await this.getCustomFields()
        this.loading = false
    }

    public closeModal () {
        this.open = false
    }

    private async mapCompleted () {
        try {
            this.closeModal()
        } catch (e) {

        }
    }

    private async getCustomFields () {
        const resp = await getListCustomFields(this.listId, {
            limit: 100,
        })
        this.customFields = resp.data.data
    }

    private async getList (listId: any) {
        try {
            const resp = await getList(listId)
            this.list = resp.data.data
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) this.$router.replace({ name: 'listsIndex' })
            }
            console.log(e)
        }
    }

    private async submit (mappedFields: any[]) {
        const mappedFieldsObject: any = {}
        mappedFields.forEach(el => {
            mappedFieldsObject[el.customFieldId] = el.field
        })
        try {
            await updateSynchronization(this.listId, this.syncId, {
                payload: {
                    mapped_fields: mappedFieldsObject,
                },
            })
            this.$root.$vsToast({
                heading: this.$t('lists.sync.syncUpdated'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.$emit('saved')
            this.closeModal()
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('lists.sync.syncUpdatedError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }
}
