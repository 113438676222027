




























































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    getSynchronizations,
    deleteSynchronization,
    updateSynchronizationActive,
} from '@/api/consoleApi/recipients'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import VsMappedFieldsModal from '@/modules/lists/components/VsMappedFieldsModal/Index.vue'
import VsImportSettingsModal from '@/modules/lists/components/VsImportSettingsModal/Index.vue'
import VsImportSourceDetailsModal from '@/modules/lists/components/VsImportSourceDetailsModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'

@Component({
    name: 'ListSync',
    components: {
        VsListingCard,
        VsSectionHeader,
        VsDropdownButton,
        VsMappedFieldsModal,
        VsImportSettingsModal,
        VsImportSourceDetailsModal,
        VsConfirm,
        MyAccordion,
    },
})
export default class extends Vue {
    private loading = false
    private showEmptyState = false
    private showDetails = false
    private syncs = []
    $refs: any

    get listId () {
        return this.$route.params.listId
    }

    beforeMount () {
        this.getSynchronizations()
    }

    async getSynchronizations () {
        try {
            const resp = await getSynchronizations(this.listId)
            this.syncs = resp.data.data
            this.showEmptyState = this.syncs.length === 0
        } catch (e) {
            console.log(e)
            this.showEmptyState = false
        }
    }

    async deleteSync (syncId: any) {
        try {
            await this.$refs.confirmDeleteSync.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await deleteSynchronization(this.listId, syncId)
            this.$root.$vsToast({
                heading: this.$t('lists.sync.syncDeleted'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('lists.sync.syncDeletedError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.getSynchronizations()
        this.loading = false
    }

    async changeStatus (syncId: any, active: boolean) {
        this.loading = true
        try {
            await updateSynchronizationActive(this.listId, syncId, {
                active,
            })
            this.$root.$vsToast({
                heading: this.$t('lists.sync.syncUpdated'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    message = 'Controlla di aver effettuato la mappatura dei campi per poter attivare la sincronizzazione'
                }
            }
            this.$root.$vsToast({
                heading: this.$t('lists.sync.syncUpdatedError'),
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.getSynchronizations()
        this.loading = false
    }
}
